import {lazy, StrictMode, Suspense} from "react";
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {LoadingOverlay, MantineProvider} from "@mantine/core";
import {ModalsProvider} from "@mantine/modals";
import {store} from "./app/store";
import theme from "./app/theme";
import App from "./app/App.tsx";

const PromptBuilder = lazy(
    () => import("./features/prompt-builder/components/PromptBuilderLayout.tsx")
);
const TestBuilder = lazy(() =>
    import("./features/promptfoo/test-builder/TestEditor.tsx").then(
        (module) => ({
            default: module.TestBuilder,
        })
    )
);
const TestsuiteEditor = lazy(() =>
    import("./features/promptfoo/test-runner/TestsuiteEditor.tsx").then(
        (module) => ({
            default: module.TestsuiteEditor,
        })
    )
);
const TestRunner = lazy(() =>
    import("./features/promptfoo/test-runner/TestRunner.tsx").then(
        (module) => ({
            default: module.TestRunner,
        })
    )
);
const TestLibrary = lazy(() =>
    import("./features/promptfoo/test-builder/TestLibrary.tsx").then(
        (module) => ({
            default: module.TestLibrary,
        })
    )
);
const ChatSettings = lazy(() =>
    import("./features/chat-settings/components/ChatSettings.tsx").then(
        (module) => ({
            default: module.ChatSettings,
        })
    )
);
const TestResultOverview = lazy(() =>
    import("./features/promptfoo/test-result/TestResultOverview.tsx").then(
        (module) => ({
            default: module.TestResultOverview,
        })
    )
);
const TestRunViewer = lazy(() =>
    import("./features/promptfoo/test-result/TestRunViewer.tsx").then(
        (module) => ({
            default: module.TestRunViewer,
        })
    )
);
const SystemPipelineEditor = lazy(() =>
    import(
        "./features/prompt-pipelines/components/SystemPipelineEditor.tsx"
    ).then((module) => ({
        default: module.SystemPipelineEditor,
    }))
);
const Assistant = lazy(
    () => import("./features/chat/components/AssistantLayout.tsx")
);
const FeedbackOverview = lazy(() =>
    import("./features/feedback/components/FeedbackOverview.tsx").then(
        (module) => ({
            default: module.FeedbackOverview,
        })
    )
);

const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <Suspense fallback={<LoadingOverlay visible={true} />}>
                <App />
            </Suspense>
        ),
        children: [
            {
                index: true,
                element: <Assistant />,
            },
            {
                path: "/chat/",
                element: <Assistant />,
            },
            {
                path: "/chat/:threadId",
                element: <Assistant />,
            },
            {
                path: "/system-pipeline",
                element: <SystemPipelineEditor />,
            },
            {
                path: "/system-pipeline/:systemId",
                element: <SystemPipelineEditor />,
            },
            {
                path: "/prompt-builder",
                element: <PromptBuilder />,
            },
            {
                path: "/prompt-builder/:promptId",
                element: <PromptBuilder />,
            },
            {
                path: "/feedback",
                element: <FeedbackOverview />,
            },
            {
                path: "/test-builder",
                element: <TestLibrary />,
            },
            {
                path: "/test-builder/editor/:testId",
                element: <TestBuilder />,
            },
            {
                path: "/test-builder/editor/new",
                element: <TestBuilder />,
            },
            {
                path: "/test-runner",
                element: <TestRunner />,
            },
            {
                path: "/test-runner/result/:testsuiteId",
                element: <TestResultOverview />,
            },
            {
                path: "/test-runner/result/:testsuiteId/:testRunId",
                element: <TestRunViewer />,
            },
            {
                path: "/test-runner/editor/:testsuiteId",
                element: <TestsuiteEditor />,
            },
            {
                path: "/test-runner/editor/new",
                element: <TestsuiteEditor />,
            },
        ],
    },
]);

createRoot(document.getElementById("root")!).render(
    <StrictMode>
        <Provider store={store}>
            <MantineProvider theme={theme}>
                <ModalsProvider>
                    <RouterProvider router={router} />
                </ModalsProvider>
            </MantineProvider>
        </Provider>
    </StrictMode>
);
