type ReferencesNode = {
    name: "references";
    children: {
        id: string;
        content: string;
        href: string;
    }[];
};

export function isReferenceNode(json: unknown): json is string {
    if (typeof json !== "string") {
        return false;
    }

    return json.includes("references");
    /* return (
        typeof json === "object" &&
        json !== null &&
        "name" in json &&
        json.name === "references"
    ); */
}

export interface TestSuiteEvaluation {
    createdAt: number;
    testsuiteId: string;
    id: string;
    config: {
        providers: string[];
        prompts: Array<{
            raw: string;
            label: string;
        }>;
        tests: Array<{
            description: string;
            vars: Record<string, string>;
            assert: Array<{
                type: string;
                value: string;
            }>;
        }>;
    };
    results: Array<{
        id: string;
        evalId: string;
        promptIdx: number;
        testIdx: number;
        testCase: {
            description: string;
            vars: Record<string, string>;
            assert: Array<{
                type: string;
                value: string;
            }>;
            options: Record<string, unknown>;
            metadata: Record<string, unknown>;
        };
        prompt: {
            raw: string;
            label: string;
        };
        promptId: string;
        error: string;
        score: number;
        success: boolean;
        response: {
            output: string;
            tokenUsage: {
                total: number;
                prompt: number;
                completion: number;
            };
            cached: boolean;
            cost: number;
        };
        gradingResult: {
            pass: boolean;
            score: number;
            reason: string;
            namedScores: Record<string, unknown>;
            tokensUsed: {
                total: number;
                prompt: number;
                completion: number;
                cached: number;
            };
            componentResults: Array<{
                pass: boolean;
                score: number;
                reason: string;
                assertion: {
                    type: string;
                    value: string;
                };
            }>;
            assertion: null | {
                type: string;
                value: string;
            };
        };
        namedScores: Record<string, unknown>;
        provider: {
            id: string;
            label: string;
            config: Record<string, unknown>;
        };
        latencyMs: number;
        cost: number;
        metadata: Record<string, unknown>;
        persisted: boolean;
    }>;
    prompts: Array<{
        raw: string;
        label: string;
        id: string;
        provider: string;
        metrics: {
            score: number;
            testPassCount: number;
            testFailCount: number;
            assertPassCount: number;
            assertFailCount: number;
            totalLatencyMs: number;
            tokenUsage: {
                total: number;
                prompt: number;
                completion: number;
                cached: number;
            };
            namedScores: Record<string, unknown>;
            namedScoresCount: Record<string, unknown>;
            cost: number;
        };
    }>;
    persisted: boolean;
}

export interface EvaluateTestSuite {
    providers: string[]; // Valid provider name (e.g. openai:gpt-4o-mini)
    prompts: string[]; // List of prompts
    tests: string | TestCase[]; // Path to a CSV file, or list of test cases

    defaultTest?: Omit<TestCase, "description">; // Optional: add default vars and assertions on test case
    outputPath?: string | string[]; // Optional: write results to file
}

export interface TestCase {
    // Optional description of what you're testing
    description?: string;

    // Key-value pairs to substitute in the prompt
    vars?: Record<string, string | string[] | object>;

    // Optional list of automatic checks to run on the LLM output
    assert?: Assertion[];

    // Additional configuration settings for the prompt
    // options?: PromptConfig & OutputConfig & GradingConfig;

    // The required score for this test case.  If not provided, the test case is graded pass/fail.
    threshold?: number;

    // Override the provider for this test
    provider?: string;
}

export interface Assertion {
    type: string;
    value?: string;
    threshold?: number; // Required score for pass
    weight?: number; // The weight of this assertion compared to other assertions in the test case. Defaults to 1.
    // provider?: ApiProvider; // For assertions that require an LLM provider
}

export type AssertionType =
    | "answer-relevance"
    | "classifier"
    | "contains-all"
    | "contains-any"
    | "contains-json"
    | "contains-sql"
    | "contains-xml"
    | "contains"
    | "context-faithfulness"
    | "context-recall"
    | "context-relevance"
    | "cost"
    | "equals"
    | "factuality"
    | "human"
    | "icontains-all"
    | "icontains-any"
    | "icontains"
    | "is-json"
    | "is-sql"
    | "is-valid-openai-function-call"
    | "is-valid-openai-tools-call"
    | "is-xml"
    | "javascript"
    | "latency"
    | "levenshtein"
    | "llm-rubric"
    | "model-graded-closedqa"
    | "model-graded-factuality"
    | "moderation"
    | "perplexity-score"
    | "perplexity"
    | "python"
    | "regex"
    | "rouge-l"
    | "rouge-n"
    | "rouge-s"
    | "select-best"
    | "similar"
    | "starts-with"
    | "webhook";

export interface PaginatedResponse<T> {
    items: T[];
    totalCount: number;
    pageNumber: number;
    pageSize: number;
    totalPages: number;
}

export interface AITest {
    meta: {
        id: string;
        name: string;
        description: string;
        createdAt: string; // ISO date string
        updatedAt: string; // ISO date string
    };
    vars: TestVariable[];
    asserts: TestAssert[];
}

export interface TestVariable {
    id: string;
    key: string;
    name: string;
    value: string;
}

export interface TestAssert {
    id: string;
    key: string;
    type: string;
    value: string;
    weight: number;
    threshold?: number; // Optional because it can be null
}

export interface TestSuite {
    meta: {
        id?: string;
        name: string;
        description: string;
        createdAt?: string;
        updatedAt?: string;
    };
    providers: string[];
    prompts: string[];
    tests: string[];
}

export type DebugPrompt = {
    id: string;
    name: string;
    description: string;
    value: string;
};

export type DebugProvider = {
    label: string;
    value: string;
};

export type FilteredContentMessage = {
    error: {
        type: string;
        message: string;
    };
};

export type ThreadMetaMessage = {
    threadid: string;
    name: string;
    datestamp: Date;
    messages: Message[];
};

export type ChatCompletionMessage = {
    id: string;
    created: number;
    model: string;
    object: string;
    system_fingerprint: string;
    choices: {
        content_filter_results: {
            hate: {
                filtered: boolean;
                severity: string;
            };
            self_harm: {
                filtered: boolean;
                severity: string;
            };
            sexual: {
                filtered: boolean;
                severity: string;
            };
            violence: {
                filtered: boolean;
                severity: string;
            };
        };
        delta: {
            content: string;
        };
        finish_reason: string | null;
        index: number;
        logprobs: null;
    }[];
};

/***** CHAT TYPES *****/

export interface BffUser {
    session_id: string;
    kundeId: number;
    kundeNavn: string;
    kontoId: number;
    kontoNavn: string;
    epost: string;
    navn: string;
    rolleId: UserRole;
    rolle: string;
    rettighet: number;
    brukerKey: string;
    spraakId: number;
    kundegruppe: string;
    produktpakke: string;
    logonDatoTidspunkt: string;
    aktiv: boolean;
    opprettet: string;
    lisensTil: string | null;
    demokonto: boolean;
    applicationId: number;
    krevSSO: boolean;
    epostSuffiks: string;
    initials: string;
    tilganger: {
        id: number;
        navn: string;
        produkter: {id: number; navn: string}[];
    }[];
}

export enum UserRole {
    Kunde = 1,
    KundePriviligert = 2,
    Fagarbeider = 3,
    Administrator = 4,
    Superbruker = 5,
    Kundeadmin = 6,
    Selger = 7,
}

export const HasNoAdminPrivileges = [UserRole.Kunde, UserRole.Selger];

export const HasAdminPrivileges = [
    UserRole.KundePriviligert,
    UserRole.Fagarbeider,
    UserRole.Administrator,
    UserRole.Superbruker,
    UserRole.Kundeadmin,
];
export interface Message {
    id?: string;
    role: "system" | "user" | "assistant";
    content: string;
    ragProcesses?: RagProcessMessage[];
    ragSources?: RagSourceMessage[];
    datestamp?: Date;
}

export interface RagProcessMessage {
    language: string;
    message: string;
    service: string;
    tag: string;
}

export interface Pipeline {
    description: string;
    model: string;
    name: string;
    promptid: string;
    tag: string;
    outputformat: string;
}

export interface RagSourceMessage {
    title: string;
    topictype: string;
    topicid: string;
    resourceid: string;
    segmentid?: string;
    bookmark?: string;
    foundin?: string;
    url?: string;
}

export type System = {
    name: string;
    queryAnalysis?: {
        pipeline: Pipeline[];
    };
    retrieval?: {
        pipeline: Pipeline[];
        searchindex: string;
    };
    completion?: {
        pipeline: Pipeline[];
    };
};

export interface Thread {
    id: string;
    name: string;
    system: System;
    datestamp: Date;
    messages: Message[];
}

export const isFilteredContent = (
    obj: unknown
): obj is FilteredContentMessage => {
    const requiredProps = ["type", "message"];
    if (typeof obj === "object" && obj !== null && "error" in obj) {
        return requiredProps.every(
            (prop) => prop in (obj as FilteredContentMessage).error
        );
    }
    return false;
};
export const isFilteredContentish = (str: unknown): boolean => {
    return typeof str === "string" && str.includes("content_filtered");
};

export type MessageType =
    | ThreadMetaMessage
    | RagProcessMessage
    | RagSourceMessage
    | ChatCompletionMessage
    | FilteredContentMessage;

export enum MessageEnum {
    ThreadMetaMessage,
    RagProcessMessage,
    RagSourceMessage,
    ChatCompletionMessage,
    FilteredContentMessage,
}

// Guards
export const isThreadMetaMessage = (obj: unknown): obj is ThreadMetaMessage => {
    return typeof obj === "object" && obj !== null && "threadid" in obj;
};

export const isRagProcessMessage = (obj: unknown): obj is RagProcessMessage => {
    const requiredProps = ["language", "message", "service", "tag"];
    return (
        typeof obj === "object" &&
        obj !== null &&
        requiredProps.every((prop) => prop in obj)
    );
};

export const isRagSourceMessage = (obj: unknown): obj is RagSourceMessage => {
    const requiredProps = ["title", "topictype", "topicid", "resourceid"];
    return (
        typeof obj === "object" &&
        obj !== null &&
        requiredProps.every((prop) => prop in obj)
    );
};

export const isChatCompletionMessage = (
    obj: unknown
): obj is ChatCompletionMessage => {
    return typeof obj === "object" && obj !== null && "choices" in obj;
};
export const isFilteredContentMessage = (
    obj: unknown
): obj is FilteredContentMessage => {
    return typeof obj === "object" && obj !== null && "filtered" in obj;
};

export const messageTypeToEnum = (obj: unknown): MessageEnum | null => {
    if (isThreadMetaMessage(obj)) {
        return MessageEnum.ThreadMetaMessage;
    }
    if (isRagProcessMessage(obj)) {
        return MessageEnum.RagProcessMessage;
    }
    if (isRagSourceMessage(obj)) {
        return MessageEnum.RagSourceMessage;
    }
    if (isChatCompletionMessage(obj)) {
        return MessageEnum.ChatCompletionMessage;
    }
    if (isFilteredContentMessage(obj)) {
        return MessageEnum.FilteredContentMessage;
    }

    return null;
};
