import {AppShell, Burger, Flex, Image, NavLink} from "@mantine/core";
import {useDisclosure} from "@mantine/hooks";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import IconMessageChatbot from "../assets/message-chatbot.svg?react";
import IconSitemap from "../assets/sitemap.svg?react";
import IconMessageCode from "../assets/message-code.svg?react";
import IconMoodAngry from "../assets/mood-angry.svg?react";
import {HoverSwitcher} from "../HoverSwitcher";

const navLinks = [
    {to: "/", label: "Chat", icon: IconMessageChatbot},
    {to: "/system-pipeline", label: "System pipeline", icon: IconSitemap},
    {to: "/prompt-builder", label: "Prompt builder", icon: IconMessageCode},
    {to: "/feedback", label: "Feedback", icon: IconMoodAngry},
    /* {to: "/test-builder", label: "Test builder", icon: TestSvg},
    {to: "/test-runner", label: "Test runner", icon: PlayerPlaySvg}, */
];

function App() {
    const [opened, {toggle}] = useDisclosure();
    const {pathname} = useLocation();
    const navigate = useNavigate();
    return (
        <>
            <HoverSwitcher />
            <Outlet />
        </>
    );

    return (
        <AppShell
            header={{height: 60}}
            navbar={{
                width: 200,
                breakpoint: "sm",
                collapsed: {mobile: !opened},
            }}
        >
            <AppShell.Header>
                <Flex h="100%" p="sm" align="center">
                    <Burger
                        opened={opened}
                        onClick={toggle}
                        hiddenFrom="sm"
                        size="sm"
                        mr="md"
                    />
                    <Image src="/dib-logo.png" h="30px" mr="4px" />
                    <sup>AI</sup>
                </Flex>
            </AppShell.Header>
            <AppShell.Navbar>
                {navLinks.map((link) => (
                    <NavLink
                        label={link.label}
                        key={link.label}
                        active={
                            (pathname === link.to && link.to === "/") ||
                            (link.to !== "/" && pathname.startsWith(link.to))
                        }
                        leftSection={<link.icon /* stroke="1.5" */ />}
                        onClick={() => navigate(link.to)}
                    />
                ))}
            </AppShell.Navbar>
            <AppShell.Main style={{height: "100dvh", overflow: "hidden"}}>
                <Outlet />
            </AppShell.Main>
        </AppShell>
    );
}

export default App;
