import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {BffUser} from "./types";

export const bffApi = createApi({
    reducerPath: "bffApi",
    baseQuery: fetchBaseQuery({baseUrl: "/"}),
    endpoints: (builder) => ({
        getUser: builder.query<BffUser, void>({
            query: () => ({
                url: `/auth/GetUser`,
                method: "GET",
            }),
        }),
    }),
});
