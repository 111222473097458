import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {System, Prompt, SystemPipelineGroup} from "./types";

interface SystemPipelineState {
    system: System | null;
}

const initialState: SystemPipelineState = {
    system: null,
};

const systemPipelineSlice = createSlice({
    name: "systemPipeline",
    initialState,
    reducers: {
        setSystemPipeline: (state, action: PayloadAction<System>) => {
            state.system = action.payload;
        },
        setPrompt: (
            state,
            action: PayloadAction<{
                id: string;
                promptId: string;
                exitontrue?: Prompt | null;
            }>
        ) => {
            const {id, promptId, exitontrue} = action.payload;

            const findAndUpdatePrompt = (pipeline: Prompt[]): boolean => {
                for (const prompt of pipeline) {
                    if (prompt.id === id) {
                        prompt.promptid = promptId;
                        if (exitontrue !== undefined) {
                            prompt.exitontrue = exitontrue;
                        }
                        return true;
                    }
                    if (prompt.exitontrue) {
                        if (findAndUpdatePrompt([prompt.exitontrue])) {
                            return true;
                        }
                    }
                }
                return false;
            };

            if (state.system) {
                const {queryAnalysis, retrieval, completion} = state.system;

                findAndUpdatePrompt(queryAnalysis.pipeline);
                findAndUpdatePrompt(retrieval.pipeline);
                findAndUpdatePrompt(completion.pipeline);
            }
        },
        setModel: (
            state,
            action: PayloadAction<{
                id: string;
                model: string;
            }>
        ) => {
            const {id, model} = action.payload;

            const findAndUpdateModel = (pipeline: Prompt[]): boolean => {
                for (const prompt of pipeline) {
                    if (prompt.id === id) {
                        prompt.model = model;
                        return true;
                    }
                    if (prompt.exitontrue) {
                        if (findAndUpdateModel([prompt.exitontrue])) {
                            return true;
                        }
                    }
                }
                return false;
            };

            if (state.system) {
                const {queryAnalysis, retrieval, completion} = state.system;

                findAndUpdateModel(queryAnalysis.pipeline);
                findAndUpdateModel(retrieval.pipeline);
                findAndUpdateModel(completion.pipeline);
            }
        },
    },
});

export const {setSystemPipeline, setPrompt, setModel} =
    systemPipelineSlice.actions;
export default systemPipelineSlice.reducer;
