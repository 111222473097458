import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {MessageFeedback, MessageFeedbackRaw, PagedResult} from "./types";

export const feedbackApi = createApi({
    reducerPath: "feedbackApi",
    baseQuery: fetchBaseQuery({baseUrl: "/api/Thread"}),
    endpoints: (builder) => ({
        getMessagesWithFeedback: builder.query<
            PagedResult<MessageFeedback>,
            {
                searchTerm?: string;
                minScore?: number;
                maxScore?: number;
                startDate?: string;
                endDate?: string;
                orderBy?: string;
                orderDirection?: string;
                pageNumber?: number;
                pageSize?: number;
            }
        >({
            query: (params) => {
                const queryParams = new URLSearchParams();
                if (params.searchTerm)
                    queryParams.append("searchTerm", params.searchTerm);
                if (params.minScore)
                    queryParams.append("minScore", params.minScore.toString());
                if (params.maxScore)
                    queryParams.append("maxScore", params.maxScore.toString());
                if (params.startDate)
                    queryParams.append("startDate", params.startDate);
                if (params.endDate)
                    queryParams.append("endDate", params.endDate);
                if (params.orderBy)
                    queryParams.append("orderBy", params.orderBy);
                if (params.orderDirection)
                    queryParams.append("orderDirection", params.orderDirection);
                if (params.pageNumber)
                    queryParams.append(
                        "pageNumber",
                        params.pageNumber.toString()
                    );
                if (params.pageSize)
                    queryParams.append("pageSize", params.pageSize.toString());

                return `/getMessagesWithFeedback?${queryParams.toString()}`;
            },
            transformResponse: (
                response: PagedResult<MessageFeedbackRaw>
            ): PagedResult<MessageFeedback> => {
                return {
                    ...response,
                    items: response.items.map((item) => ({
                        ...item,
                        messageJson: JSON.parse(item.messageJson),
                    })),
                };
            },
        }),
    }),
});
