import {
    createTheme,
    rem,
    defaultVariantColorsResolver,
    Button,
    Tooltip,
    Modal,
    darken,
    Menu,
    MantineColorsTuple,
} from "@mantine/core";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import classes from "./variants.module.css";

export const DIB_DARKEST_OF_GREENS = "#012C3D";
export const DIB_DARK_GREEN = "#296D5D";
export const DIB_GREEN = "#35917C";
export const DIB_LIGHT_GREEN = "#97F2DD";
export const DIB_DARK_BLUE = "#012C3D";

const dibblue: MantineColorsTuple = [
    "#dce4f5",
    "#b9c7e2",
    "#94a8d0",
    "#748dc0",
    "#5f7cb7",
    "#5474b4",
    "#44639f",
    "#3a5890",
    "#2c4b80",
    "#012C3D",
];

const dibpurple: MantineColorsTuple = [
    "#eaeaff",
    "#cfcfff",
    "#9b9bff",
    "#6464ff",
    "#3736fe",
    "#1b19fe",
    "#0909ff",
    "#0000e4",
    "#0000cc",
    "#0000b4",
];

export default createTheme({
    /* fontFamily: "Lato, sans-serif", */
    colors: {
        dibblue,
        dibpurple,
    },
    fontFamily: "Lato, sans-serif",
    fontSizes: {
        xxl: rem(24),
        xxxl: rem(30),
    },
    components: {
        Button: Button.extend({
            classNames: classes,
            defaultProps: {size: "xs", radius: "md"},
        }),
        Tooltip: Tooltip.extend({
            defaultProps: {
                openDelay: 300,
                position: "bottom",
            },
        }),
        Modal: Modal.extend({
            defaultProps: {
                radius: "lg",
                transitionProps: {transition: "fade", duration: 150},
            },
            styles: {
                title: {
                    fontWeight: 600,
                },
            },
        }),
        Menu: Menu.extend({
            defaultProps: {
                radius: "md",
            },
        }),
    },
    variantColorResolver: (input) => {
        const defaultResolvedColors = defaultVariantColorsResolver(input);

        // Make variant "light" with color "gray" a bit darker for visiblity and make hover darker so hover is visible.
        if (input.variant === "light" && input.color === "gray") {
            const defaultColors = defaultVariantColorsResolver({
                ...input,
                variant: "light",
            });

            return {
                ...defaultColors,
                background: darken(defaultColors.background, 0.02),
                color: darken(defaultColors.color, 0.5),
                hover: darken(defaultColors.hover, 0.05),
            };
        }

        // Make variant "message-button" use "subtle" variant default colors.
        // New style overrides are found in variants.module.css.
        if (input.variant === "message-button") {
            return defaultVariantColorsResolver({
                ...input,
                variant: "subtle",
            });
        }

        if (input.variant === "rounded") {
            return defaultVariantColorsResolver({
                ...input,
                variant: "light",
            });
        }

        return defaultResolvedColors;
    },
});
