import {
    HoverCard,
    Stack,
    Text,
    Box,
    Avatar,
    Button,
    Center,
    Group,
    SegmentedControl,
    Divider,
} from "@mantine/core";
import IconMessageChatbot from "./assets/message-chatbot.svg?react";
import IconSitemap from "./assets/sitemap.svg?react";
import IconMessageCode from "./assets/message-code.svg?react";
import IconMoodAngry from "./assets/mood-angry.svg?react";
import IconDiamond from "./assets/diamond.svg?react";
import IconDiamondFilled from "./assets/diamond-filled.svg?react";
import {useLocation, useNavigate} from "react-router-dom";
import classes from "./HoverSwitcher.module.css";
import {useLocalStorage} from "@mantine/hooks";

const navLinks = [
    {to: "/", label: "Chat", icon: IconMessageChatbot},
    {to: "/system-pipeline", label: "System pipeline", icon: IconSitemap},
    {to: "/prompt-builder", label: "Prompt builder", icon: IconMessageCode},
    {to: "/feedback", label: "Feedback", icon: IconMoodAngry},
];

export function HoverSwitcher() {
    const [version, setVersion] = useLocalStorage({
        key: "version",
        defaultValue: "Production",
    });

    const pathname = useLocation().pathname;
    const navigate = useNavigate();
    const links = navLinks.map((link) => {
        const Icon = link.icon;
        return (
            <Button
                key={link.to}
                onClick={() => navigate(link.to)}
                variant="white"
                color="black"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    "--button-hover": "var(--mantine-color-gray-1)",
                }}
            >
                {pathname === link.to ? (
                    <Text size="xl">👉</Text>
                ) : (
                    <Icon style={{width: "20px", height: "20px"}} />
                )}

                <Text pl="xs" size="sm">
                    {link.label}
                </Text>
            </Button>
        );
    });

    return (
        <Box
            style={{
                position: "fixed",
                bottom: "1rem",
                right: "1rem",
                zIndex: 1000,
            }}
        >
            <HoverCard width={200} position="top" withArrow>
                <HoverCard.Target>
                    <Avatar
                        className={classes.hoverSwitcher}
                        radius="xl"
                        size="md"
                        variant="white"
                        color="gray"
                    >
                        <IconDiamondFilled className={classes.showOnHover} />
                        <IconDiamond className={classes.hideOnHover} />
                    </Avatar>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                    <Stack gap="sm">
                        <Center>
                            <Group>
                                <Text size="xl">👀</Text>
                                <Text size="xs" c="dimmed">
                                    Admins' eyes only!
                                </Text>
                            </Group>
                        </Center>
                        {links}
                        <Divider />
                        <Center>
                            <Text size="xs">API version</Text>
                        </Center>
                        <SegmentedControl
                            fullWidth
                            value={version}
                            orientation="vertical"
                            data={["Production", "Beta", "Alpha"]}
                            onChange={(value) => setVersion(value)}
                        />
                    </Stack>
                </HoverCard.Dropdown>
            </HoverCard>
        </Box>
    );
}
