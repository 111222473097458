import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export interface Prompt {
    id: string;
    name: string;
    tag: string;
    content: string;
    brukerkey: string;
    datestamp: string;
}

export interface PromptFields {
    name: string;
    tag: string;
    content: string;
}

interface OpenAICompletionRequest {
    model: string;
    messages: {
        role: string;
        content: string;
    }[];
    temperature?: number;
    max_tokens?: number;
}

interface OpenAICompletionResponse {
    id: string;
    object: string;
    created: number;
    model: string;
    usage: {
        prompt_tokens: number;
        completion_tokens: number;
        total_tokens: number;
    };
    choices: {
        message: {
            role: string;
            content: string;
        };
        finish_reason: string;
        index: number;
    }[];
}

const openaiKey =
    "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";

export const promptApi = createApi({
    reducerPath: "promptApi",
    tagTypes: ["PromptList"],
    baseQuery: fetchBaseQuery({baseUrl: "/api/prompt"}),
    endpoints: (builder) => ({
        getPrompt: builder.query<Prompt, string>({
            query: (id) => `/getPrompt/${id}`,
        }),
        getPrompts: builder.query<Prompt[], void>({
            query: () => "/getPrompt",
            transformResponse: (response: Prompt[]) => {
                const priority: Record<string, number> = {
                    system: 1,
                    variable: 2,
                };

                // Reverse the order so that the oldest system prompts are at the top
                return response.reverse().sort((a, b) => {
                    return (priority[a.tag] || 3) - (priority[b.tag] || 3);
                });
            },
            providesTags: ["PromptList"],
        }),
        createPrompt: builder.mutation<
            Prompt,
            PromptFields & {onSuccess?: (data: Prompt) => void}
        >({
            query: (body) => ({
                url: "addPrompt",
                method: "POST",
                body,
            }),
            invalidatesTags: ["PromptList"],
            onQueryStarted: async ({onSuccess}, {queryFulfilled}) => {
                const result = await queryFulfilled;

                if (result.data) {
                    onSuccess?.(result.data);
                }
            },
        }),
        updatePrompt: builder.mutation<void, PromptFields & {id: string}>({
            query: (body) => ({
                url: `/changePrompt`,
                method: "POST",
                body,
            }),
            invalidatesTags: ["PromptList"],
        }),
        deletePrompt: builder.mutation<void, string>({
            query: (id) => ({
                url: `/deletePrompt?Id=${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["PromptList"],
        }),
        openaiCompletion: builder.mutation<
            OpenAICompletionResponse,
            OpenAICompletionRequest
        >({
            query: (body) => ({
                url: "https://api.openai.com/v1/chat/completions",
                method: "POST",
                body,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${openaiKey}`,
                },
            }),
        }),
    }),
});

export const {
    useGetPromptQuery,
    useGetPromptsQuery,
    useCreatePromptMutation,
    useUpdatePromptMutation,
    useDeletePromptMutation,
    useOpenaiCompletionMutation,
} = promptApi;
