import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {System} from "./types";
import {setSystem} from "../chat/chatSlice";
import {setSystemPipeline} from "./systemPipelineSlice";

export const productPipelineApi = createApi({
    reducerPath: "productPipelineApi",
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/System",
    }),
    endpoints: (builder) => ({
        getProductPipeline: builder.query<System[], void>({
            query: () => "/GetSystems",
            onQueryStarted: async (_, {queryFulfilled, dispatch}) => {
                try {
                    const {data} = await queryFulfilled;

                    if (data && data.length > 0) {
                        dispatch(setSystemPipeline(data[0]));
                        /* dispatch(setSystem({name: data[0].name})); why is this needed? */
                    }
                } catch (error) {
                    console.error("Failed to fetch product pipelines:", error);
                }
            },
        }),
    }),
});

export const {useGetProductPipelineQuery} = productPipelineApi;
