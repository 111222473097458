import React, {useEffect, useRef, useState} from "react";

export const API_SUCCESS = "API_SUCCESS";
export const API_TIMEOUT = "API_TIMEOUT";
export const API_CHECK_TIME = 5 * 60 * 1000; // five minutes

function checkSessionStatus(response: Response) {
    return new Promise((resolve) => {
        if (response.ok) {
            if (document) {
                document.dispatchEvent(
                    new CustomEvent(API_SUCCESS, {detail: {success: true}})
                );
            }
            resolve({});
        } else if (response.status === 401) {
            console.error("Authentication error");
            if (document) {
                document.dispatchEvent(new CustomEvent(API_TIMEOUT));
            }
        } else {
            resolve({
                ERROR: true,
            });
        }
    });
}

type SessionPingError = {
    msg: any;
    ERROR: boolean;
};

const sessionOptions: RequestInit = {
    method: "GET",
    headers: new Headers({
        Accept: "application/json",
    }),
    cache: "no-cache",
    credentials: "same-origin",
};

function sessionPing() {
    return fetch("/api/ping", sessionOptions)
        .then(checkSessionStatus)
        .catch((e) => {
            return {
                msg: e,
                ERROR: true,
            };
        });
}

const SessionKeeper = () => {
    const checkSessionTimerRef = useRef<NodeJS.Timeout | null>(null);
    const [sessionExpired, setSessionExpired] = useState(false);

    const startCheckSessionTimer = () => {
        if (checkSessionTimerRef.current) {
            clearTimeout(checkSessionTimerRef.current);
        }

        checkSessionTimerRef.current = setTimeout(() => {
            sessionPing().then((r) => {
                if ((r as SessionPingError)?.ERROR) {
                    setSessionExpired(true);
                }
            });
        }, API_CHECK_TIME);
    };

    const handleSetSessionExpired = () => {
        setSessionExpired(true);
    };

    const triggerLogin = () => {
        setSessionExpired(false);
        window.location.href =
            "/auth/dib_login?returnUrl=" +
            encodeURIComponent(
                window.location.pathname + window.location.search
            );
    };

    useEffect(() => {
        document.addEventListener(API_TIMEOUT, handleSetSessionExpired);
        document.addEventListener(API_SUCCESS, startCheckSessionTimer);

        return () => {
            document.removeEventListener(API_TIMEOUT, handleSetSessionExpired);
            document.removeEventListener(API_SUCCESS, startCheckSessionTimer);
        };
    }, []);

    useEffect(() => {
        if (!sessionExpired) {
            return;
        }

        // If the user is not focused on the tab, wait for them to focus on the tab before triggering login
        if (document.hidden) {
            window.addEventListener("focus", triggerLogin);

            return () => {
                window.removeEventListener("focus", triggerLogin);
            };
            // If the user is focused on the tab, trigger login immediately
        } else {
            triggerLogin();
        }
    }, [sessionExpired]);

    return <></>;
};

export default SessionKeeper;
